<template>
  <div
    class="artist-valuation-content mb-20"
    v-loading.fullscreen.lock="loading"
  >
    <div class="px-0">
      <CardToolbar
        style="background: white"
        :show-header="true"
        :show-toolbar-buttons="true"
        :fontStyle="true"
        :padding="true"
        :shadow-class="true"
        :margin="true"
        :title="''"
      >
        <template v-slot:toolbar>
          <el-popover
            effect="dark"
            placement="top-start"
            title="Update"
            trigger="hover"
            popper-class="popover-update"
          >
            <template #reference>
              <el-icon
                class="me-2 cursor-pointer"
                size="40"
                color="#33647f"
                @click="getArtistDetail(true)"
              >
                <Refresh />
              </el-icon>
            </template>
          </el-popover>
          <el-dropdown>
            <div
              style="
                background: rgb(1, 88, 134);
                border-radius: 100%;
                padding: 8px;
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="m12.53 3.39 5 5-1.06 1.06-3.72-3.719v9.19h-1.5V5.73l-3.72 3.72-1.06-1.06 5-5L12 2.86l.53.53Zm-9.28 10v7.75h17.5v-7.75h-1.5v6.25H4.75v-6.25h-1.5Z"
                  fill="white"
                ></path>
              </svg>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="dropdown-menu-v2">
                <!--                  <el-dropdown-item @click="copyUrl">Copy Url</el-dropdown-item>-->
                <el-dropdown-item
                  v-if="links.facebook && links.facebook.length"
                >
                  <div class="d-flex">
                    <a :href="links.facebook[0]" target="_blank">
                      <svg
                        class="icon-style"
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#0084ff"
                      >
                        <path
                          d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0 4.5c-3.314 0-6 2.487-6 5.556 0 1.748.872 3.307 2.236 4.326v2.118l2.043-1.121c.545.151 1.123.232 1.721.232 3.314 0 6-2.487 6-5.555 0-3.069-2.686-5.556-6-5.556zm.596 7.481l-1.528-1.629-2.981 1.629 3.279-3.481 1.566 1.63 2.944-1.63-3.28 3.481z"
                        />
                      </svg>
                      <span class="ms-2">Facebook</span>
                    </a>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="links.instagram && links.instagram.length"
                >
                  <div class="d-flex">
                    <a :href="links.instagram[0]" target="_blank">
                      <svg
                        class="icon-style"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#e1306c"
                      >
                        <path
                          d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.082c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424s-.006 1.792-.034 2.424c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424s.007-1.792.035-2.424c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034zm0-1.082c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037zm0 2.919c-1.701 0-3.081 1.379-3.081 3.081s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081c0-1.701-1.38-3.081-3.081-3.081zm0 5.081c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2s-.897 2-2.001 2zm3.202-5.922c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72z"
                        />
                      </svg>
                      <span class="ms-2">instagram</span>
                    </a>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="links.twitter && links.twitter.length">
                  <div class="d-flex">
                    <a :href="links.twitter[0]" target="_blank">
                      <svg
                        class="icon-style"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#00acee"
                      >
                        <path
                          d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.5 8.778c-.441.196-.916.328-1.414.388.509-.305.898-.787 1.083-1.362-.476.282-1.003.487-1.564.597-.448-.479-1.089-.778-1.796-.778-1.59 0-2.758 1.483-2.399 3.023-2.045-.103-3.86-1.083-5.074-2.572-.645 1.106-.334 2.554.762 3.287-.403-.013-.782-.124-1.114-.308-.027 1.14.791 2.207 1.975 2.445-.346.094-.726.116-1.112.042.313.978 1.224 1.689 2.3 1.709-1.037.812-2.34 1.175-3.647 1.021 1.09.699 2.383 1.106 3.773 1.106 4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z"
                        />
                      </svg>
                      <span class="ms-2">Twitter</span>
                    </a>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="links.youtube && links.youtube.length">
                  <div class="d-flex">
                    <a :href="links.youtube[0]" target="_blank">
                      <svg
                        class="icon-style"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#ff0000"
                      >
                        <path
                          d="M16.23 7.102c-2.002-.136-6.462-.135-8.461 0-2.165.148-2.419 1.456-2.436 4.898.017 3.436.27 4.75 2.437 4.898 1.999.135 6.459.136 8.461 0 2.165-.148 2.42-1.457 2.437-4.898-.018-3.436-.271-4.75-2.438-4.898zm-6.23 7.12v-4.444l4.778 2.218-4.778 2.226zm2-12.222c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"
                        />
                      </svg>
                      <span class="ms-2">Youtube</span>
                    </a>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="links.soundcloud && links.soundcloud.length"
                >
                  <div class="d-flex">
                    <a :href="links.soundcloud[0]" target="_blank">
                      <svg
                        class="icon-style"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#f26f23"
                      >
                        <path
                          d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.709 15h-.541v-4.4c.167-.126.347-.234.541-.309v4.709zm1.625 0h.543v-5.031c-.125.145-.24.299-.337.468l-.206-.101v4.664zm-1.082 0h.541v-4.826c-.274-.043-.337-.027-.541-.005v4.831zm-2.168 0h.543v-3.829c-.17.25-.302.529-.375.836l-.168-.099v3.092zm-1.625-2.863c-.329.298-.541.738-.541 1.236 0 .499.212.938.541 1.238v-2.474zm10.227-.366c-.11-1.548-1.302-2.771-2.772-2.771-.554 0-1.064.179-1.496.479v5.521h4.924c.87 0 1.576-.76 1.576-1.695 0-1.218-1.174-2.056-2.232-1.534zm-9.143-.016c-.163-.014-.285-.017-.541.066v3.103c.241.078.345.076.541.076v-3.245z"
                        />
                      </svg>
                      <span class="ms-2">Soundcloud</span>
                    </a>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
        <template v-slot:body>
          <div class="row ms-16 mb-16">
            <div class="col-6">
              <div class="d-flex flex-column">
                <div
                  class="theme-v2-color"
                  style="
                    font-size: 3.5rem;
                    line-height: 1.17;
                    font-weight: 600 !important;
                  "
                >
                  {{
                    artistDetail && artistDetail.name ? artistDetail.name : ""
                  }}
                </div>
                <div class="mt-4">
                  <el-tag size="small" effect="dark" class="fw-bolder">
                    Artist
                  </el-tag>
                </div>
                <div class="mt-20 d-flex flex-row">
                  <h3 class="fw-boldest theme-v2-color">
                    ARTIST VALUATION EST.
                  </h3>
                  <el-popover
                    effect="dark"
                    placement="right"
                    title="Estimated Range:"
                    :width="300"
                    trigger="hover"
                    popper-class="popover-artist-valuation"
                  >
                    <template #reference>
                      <el-icon>
                        <InfoFilled class="ms-2 cursor-pointer" />
                      </el-icon>
                    </template>
                    <template #default>
                      <p class="mb-2 fw-bolder">$15.92m - $21.54m</p>
                      <p class="popover-text fw-bold">
                        This represents the fair market value of the song's
                        estimated future master streaming royalties. The
                        estimate is calculated by analyzing factors like genre,
                        age, recent trends, historical performance of comparable
                        songs, and more, and assumes $0.0036 per stream.
                      </p>
                    </template>
                  </el-popover>
                </div>
                <div class="d-flex flex-row mt-2">
                  <div style="font-size: 2rem !important" class="sub-text">
                    $
                    {{
                      artistDetail && artistDetail.artistValue
                        ? checkIsValueAvailableOrNot(
                            "object",
                            artistDetail.artistValue,
                            "artistValuation"
                          )
                        : 0
                    }}
                  </div>
                  <div class="ms-8 mt-1">
                    <el-tag size="small" effect="dark" class="fw-bolder">
                      BETA
                    </el-tag>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-between">
                  <div class="mt-16">
                    <div class="d-flex flex-row">
                      <h3 class="fw-boldest theme-v2-color">
                        STREAMS (PAST 12 MONTHS)
                      </h3>
                      <el-popover
                        effect="dark"
                        placement="right"
                        :width="300"
                        trigger="hover"
                        popper-class="popover-artist-valuation"
                      >
                        <template #reference>
                          <el-icon>
                            <InfoFilled class="ms-2 cursor-pointer" />
                          </el-icon>
                        </template>
                        <template #default>
                          <p class="popover-text fw-bold">
                            This is an estimate of the global streams that
                            Flowers by Miley Cyrus has received over the past 12
                            months.
                          </p>
                        </template>
                      </el-popover>
                    </div>
                    <div class="d-flex">
                      <p class="sub-text mt-2">
                        {{
                          artistDetail && artistDetail.artistValue
                            ? checkIsValueAvailableOrNot(
                                "object",
                                artistDetail.artistValue,
                                "trackStreamsValuation"
                              )
                            : 0
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="mt-16">
                    <div class="d-flex flex-row">
                      <h3 class="fw-boldest theme-v2-color">EST. ROYALTIES</h3>
                      <el-popover
                        effect="dark"
                        placement="right"
                        :width="300"
                        trigger="hover"
                        popper-class="popover-artist-valuation"
                      >
                        <template #reference>
                          <el-icon>
                            <InfoFilled class="ms-2 cursor-pointer" />
                          </el-icon>
                        </template>
                        <template #default>
                          <p class="popover-text fw-bold">
                            This is an estimate of the total master streaming
                            royalties earned for the right shoulders of this
                            song over the last 6 months, based on an assumed
                            rate of $0.0036 per stream. While this rate
                            represents the historical average observed across
                            songs on Royal, the actual rate for this song may be
                            higher or lower.
                          </p>
                        </template>
                      </el-popover>
                    </div>
                    <div class="d-flex">
                      <p class="sub-text mt-2">
                        $
                        {{
                          artistDetail && artistDetail.artistValue
                            ? checkIsValueAvailableOrNot(
                                "object",
                                artistDetail.artistValue,
                                "estimatedRoyalties"
                              )
                            : 0
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="text-center">
                <img
                  style=""
                  class="artist-image"
                  width="370"
                  height="370"
                  :src="
                    artistDetail && artistDetail.imageUrl
                      ? artistDetail.imageUrl
                      : '/media/avatars/blank.png'
                  "
                  alt="Billie"
                />
              </div>
            </div>
          </div>
        </template>
      </CardToolbar>
      <div class="d-flex flex-column mt-20" style="margin-left: 7rem">
        <h1 class="theme-v2-color">More About the Artist</h1>
        <div class="mt-6">
          <p class="facts-figures">
            Monthly Listeners :
            <span class="fw-700 ms-2">{{
              checkIsValueAvailableOrNot(
                "object",
                artistDetail,
                "chartmetricSpotifyListenersTotal"
              )
            }}</span>
          </p>
          <p class="facts-figures">
            36M Growth :
            <span class="fw-700 ms-12">{{
              artistDetail
                ? checkIsValueAvailableOrNot(
                    "object",
                    artistDetail.chartmetricSpotifyListenersGrowth,
                    "36Months",
                    "%"
                  )
                : 0
            }}</span>
          </p>
          <p class="facts-figures">
            Revenues :
            <span class="fw-700 ms-17">
              $
              {{
                checkIsValueAvailableOrNot(
                  "object",
                  artistDetail,
                  "chartmetricRevenue"
                )
              }}</span
            >
          </p>
          <p class="facts-figures">
            Playlists :
            <span class="fw-700 ms-20">{{
              checkIsValueAvailableOrNot(
                "object",
                artistDetail.chartmetricMeta &&
                  artistDetail.chartmetricMeta.cm_statistics
                  ? artistDetail.chartmetricMeta.cm_statistics
                  : {},
                "num_sp_playlists"
              )
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardToolbar from "@/common/components/CardToolbar.vue";
import { InfoFilled, Refresh } from "@element-plus/icons-vue";
import { onMounted, ref } from "vue";
import { getArtistValue } from "@/api/mongoDB";
import { useRoute } from "vue-router";
import {
  checkIsValueAvailableOrNot,
  getSocialSitesFromChartMetricArray,
} from "@/utils/staticHelper";
import { ElNotification } from "element-plus";

export default {
  name: "ArtistValuation",
  methods: { checkIsValueAvailableOrNot },
  components: { Refresh, InfoFilled, CardToolbar },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const artistDetail = ref({});
    const links = ref({});
    onMounted(async () => {
      await getArtistDetail();
    });

    const getArtistDetail = async (update = false) => {
      try {
        loading.value = true;
        const { data } = await getArtistValue(route.params.spotifyID, update);
        artistDetail.value = data;
        links.value = getSocialSitesFromChartMetricArray(
          artistDetail.value.chartmetricContacts.contacts
        );
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const copyUrl = () => {
      navigator.clipboard.writeText(
        `${window.location.origin}/artist-valuation/${route.params.spotifyID}`
      );
      ElNotification({
        title: "Url",
        message: "Copied to clipboard",
        type: "success",
      });
    };
    return { loading, artistDetail, links, copyUrl, getArtistDetail };
  },
};
</script>
<style lang="scss">
.popover-artist-valuation {
  .el-popover__title {
    font-weight: 700 !important;
  }
}

.popover-update {
  .el-popover__title {
    text-align: center;
  }
}
</style>
<style scoped>
.el-dropdown-menu__item:hover {
  color: #009ef7 !important;
}

.facts-figures {
  color: #33647f !important;
  margin-bottom: 0.5rem;
}

.sub-text {
  color: #7e89a5;
  font-size: 2rem;
  line-height: 2.5rem;
}

.artist-image {
  border-radius: 1rem;
  transform: scale(1.05);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
  transition: transform 1s ease-in-out;
}

.artist-image:hover {
  transform: scale(1.1);
}

.popover-text {
  word-break: break-word;
}
</style>
